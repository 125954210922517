import React from "react"

interface BlogPostTagPropsTypes {
  title: string
}

const BlogPostTag: React.FC<BlogPostTagPropsTypes> = ({ title }) => {
  return (
    <li className="post-content-header__tags-item">
      <div className="post-content-header__tag card-tag">{title}</div>
    </li>
  )
}

export default BlogPostTag
