import React from "react"
import TransitionFadeLink from "../../UI/TransitionFadeLink"
import "./breadcrumbs.scss"
interface BreadcrumbsPropsTypes {
  pathname: string
  slug: string
  title: string
}

const Breadcrumbs: React.FC<BreadcrumbsPropsTypes> = ({
  pathname,
  slug,
  title,
}) => {
  return (
    <section className="container breadcrumbs">
      <div className="container__item">
        <TransitionFadeLink
          className="breadcrumbs__link text text--sub2"
          url={pathname.replace(slug, "") + "/"}
        >
          Blog
        </TransitionFadeLink>
        <span className="breadcrumbs__title text text--sub2">{title}</span>
      </div>
    </section>
  )
}

export default Breadcrumbs
