import React from "react"
import Circle from "../../Circle"
import Footer from "../../UI/Footer"
import { NodesFooterArrayType } from "@typings/index"
import "./post-bottom.scss"

interface PostBottomPropsTypes {
  footerData: NodesFooterArrayType
}

const PostBottom: React.FC<PostBottomPropsTypes> = ({ footerData }) => {
  return (
    <div className="blog-post-footer">
      <div className="container blog-post-footer__container">
        <div className="blog-post-footer-circle blog-post-footer-circle--big-blue">
          <Circle color="#431B93" />
        </div>
        <div className="blog-post-footer-circle blog-post-footer-circle--small-white">
          <Circle color="#FFFFFF" />
        </div>
        <Footer className="blog-post-footer__footer" {...footerData} />
      </div>
    </div>
  )
}

export default PostBottom
