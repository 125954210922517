import React from "react"
import cn from "classnames"
import { Parallax } from "react-scroll-parallax"
import Circle from "../../Circle"
import { AnimatedCirclePropsTypes } from "@typings/props"

export const BigBlueCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
}) => (
  <div
    className={cn("blog-post-circle blog-post-circle--big-blue", {
      "h-in-view": isView,
    })}
  >
    <Parallax translateY={[35, -35]}>
      <Circle color="#431B93" />
    </Parallax>
  </div>
)

export const BigWhiteCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
}) => (
  <div
    className={cn("blog-post-circle blog-post-circle--big-white", {
      "h-in-view": isView,
    })}
  >
    <Parallax translateY={[25, -25]}>
      <Circle color="#FFFFFF" isSolid />
    </Parallax>
  </div>
)

export const SmallGreenCircle: React.FC<AnimatedCirclePropsTypes> = ({
  isView,
}) => (
  <div
    className={cn("blog-post-circle blog-post-circle--small-green", {
      "h-in-view": isView,
    })}
  >
    <Parallax translateY={[-20, 20]}>
      <Circle color="#009B3A" isSolid />
    </Parallax>
  </div>
)
