import React, { useState } from "react"
import { InView } from "react-intersection-observer"
import {
  BigBlueCircle,
  BigWhiteCircle,
  SmallGreenCircle,
} from "./PostContentCircles"
import BlogPostTag from "./BlogPostTag"
import { PostContentDataType } from "@typings/requests/home/blog-post"
import "./post-content.scss"

type PostContentPropsTypes = PostContentDataType

const PostContent: React.FC<PostContentPropsTypes> = ({
  title,
  tags,
  publishedAt,
  adminUser,
  text,
}) => {
  const [isInView, setIsInView] = useState(false)
  return (
    <InView
      as="section"
      triggerOnce
      className="container post-content__container"
      threshold={[0.1]}
      onChange={setIsInView}
    >
      <BigBlueCircle isView={isInView} />
      <BigWhiteCircle isView={isInView} />
      <SmallGreenCircle isView={isInView} />
      <div className="post-content">
        <h1 className="post-content__title text text--h1">{title}</h1>
        <div className="post-content__header post-content-header">
          {tags.length > 0 && (
            <ul className="post-content-header__tags list-unstyled">
              {tags.map(tag => (
                <BlogPostTag {...tag} key={tag.id} />
              ))}
            </ul>
          )}
          <time
            className="post-content-header__time text text--caption1 text--grey-75"
            dateTime={publishedAt}
          >
            {publishedAt}
          </time>
          {adminUser && (
            <span className="post-content-header__author text text--grey-75">{`${adminUser.firstname} ${adminUser.lastname}`}</span>
          )}
        </div>
        <div
          className="post-content__body"
          dangerouslySetInnerHTML={{ __html: text?.data?.text || "" }}
        />
      </div>
    </InView>
  )
}

export default PostContent
