import React, { useMemo } from "react"
import { graphql } from "gatsby"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { ParallaxProvider } from "react-scroll-parallax"
import LayoutContainer from "@containers/Layout"
import Breadcrumbs from "@components/BlogPost/Breadcrumbs"
import SEO from "@components/SEO"
import PostContent from "@components/BlogPost/PostContent"
import PostSubscribe from "@components/BlogPost/PostSubscribe"
import BlogFeaturedPosts from "@components/Blog/BlogFeaturedPosts"
import PostBottom from "@components/BlogPost/PostBottom"
import { BlogPostPageDataType } from "@typings/requests/home/blog-post"
import { CAPTCHA_KEY } from "@helpers/constants"
import "./blog-post.scss"

interface BlogPostPagePropsTypes {
  data: BlogPostPageDataType
  pageContext: {
    locale: string
    slug: string
    pathname: string
    multilanguageSlug: string
  }
  location: Window["location"]
}

const BlogPost: React.FC<BlogPostPagePropsTypes> = ({
  data,
  location,
  pageContext,
}) => {
  const {
    featuredPosts: { nodes: featuredPostsData },
    allStrapiPageFooter: {
      nodes: [footerData],
    },
    blog: {
      nodes: [blogData],
    },
    strapiPosts: blogPostData,
    localesSlugs: { nodes: localesSlugs },
  } = data
  const { seoTitle, title, description, image } = blogPostData

  const normalizedLocalesSlugs = useMemo(
    () =>
      localesSlugs &&
      localesSlugs.reduce(
        (obj, item) => ({ ...obj, [item.locale]: item.slug }),
        {}
      ),
    [localesSlugs]
  )

  return (
    <>
      <SEO
        title={seoTitle || title}
        description={description}
        image={image.url}
        pathname={location.pathname}
        lang={pageContext.locale}
      />
      <GoogleReCaptchaProvider reCaptchaKey={CAPTCHA_KEY}>
        <ParallaxProvider>
          <LayoutContainer
            className="h-bg-yellow"
            location={location}
            lang={pageContext.locale}
            hideLangSwitcher={Object.entries(normalizedLocalesSlugs).length < 2}
            normalizedLocalesSlugs={normalizedLocalesSlugs}
          >
            <Breadcrumbs
              slug={pageContext.slug}
              title={title}
              pathname={location.pathname}
            />
            <PostContent {...blogPostData} />
            <PostSubscribe
              {...blogData}
              lang={pageContext.locale}
              isFeaturedPosts={Boolean(featuredPostsData?.length)}
            />
            {featuredPostsData.length > 0 && (
              <div className="post-related">
                <BlogFeaturedPosts
                  posts={featuredPostsData}
                  title={"Related posts"}
                />
              </div>
            )}
            <PostBottom footerData={footerData} />
          </LayoutContainer>
        </ParallaxProvider>
      </GoogleReCaptchaProvider>
    </>
  )
}

export const query = graphql`
  query PostPageQuery(
    $slug: String!
    $relatedTag: String!
    $locale: String!
    $multilanguageSlug: String!
  ) {
    strapiPosts(slug: { eq: $slug }, locale: { eq: $locale }) {
      slug
      text {
        data {
          text
        }
      }
      title
      locale
      seoTitle
      description
      publishedAt(formatString: "DD MM, YYYY", locale: $locale)
      tags {
        name
        id
        title
      }
      adminUser {
        firstname
        lastname
      }
      image {
        url
      }
    }
    localesSlugs: allStrapiPosts(
      filter: { multilanguageSlug: { eq: $multilanguageSlug } }
    ) {
      nodes {
        slug
        locale
      }
    }
    blog: allStrapiBlog(filter: { locale: { eq: $locale } }) {
      nodes {
        locale
        subscribeTitle
        subscribeButton
        subscribeInputError
        subscribeInputLabel
        subscribeSuccessLabel
        subscribeFailLabel
      }
    }
    featuredPosts: allStrapiPosts(
      limit: 3
      filter: {
        locale: { eq: $locale }
        tags: { elemMatch: { name: { eq: $relatedTag } } }
        slug: { ne: $slug }
      }
      sort: { order: DESC, fields: publishedAt }
    ) {
      nodes {
        slug
        id
        locale
        publishedAt(formatString: "YYYY-MM-DD", locale: $locale)
        tags {
          name
          title
          id
        }
        title
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 90
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 375
                height: 225
              )
            }
          }
        }
      }
    }
    allStrapiPageFooter(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        locale
        socialLink {
          name
          title
          url
        }
      }
    }
  }
`

export default BlogPost
